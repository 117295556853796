/**
 * @flow
 */
import React, { useState, useEffect } from "react"
import PageWrapper from "../components/PageWrapper"
import styled from "styled-components"

import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import underlineImg from "../images/landing/underline-scribble.svg"

import { scroller } from "react-scroll"

type Props = {
  location: Object,
}

const Header = styled.div`
  margin: 50px 0;
  text-align: center;
  position: relative;
  h1 {
    font-size: 1.5rem;
    font-family: ${props => props.theme.font.galanoBold};
    color: ${props => props.theme.color.grey};
  }
  p {
    font-size: 1.2rem;
    font-family: ${props => props.theme.font.saturdayRock};
  }
  span {
    background-image: url(${underlineImg});
    background-repeat: no-repeat;
    background-position: 50%;
    height: 10px;
    display: block;
  }
  @media (min-width: 768px) {
    h1 {
      font-size: 2.5rem;
    }
    p {
      font-size: 1.6rem;
      position: absolute;
      left: 50%;
      transform: rotate(-2deg);
    }
    span {
      transform: translateX(15px);
      background-position: right;
      height: 6px;
    }
  }
`

const Section = styled.div`
  margin-bottom: 4rem;
  h2 {
    font-size: 1.5rem;
    font-family: ${props => props.theme.font.galanoBold};
    margin-bottom: 2rem;
    color: ${props => props.theme.color.grey};
    text-transform: uppercase;
  }
  @media (min-width: 768px) {
    h2 {
      font-size: 1.75rem;
    }
  }
`

const AccordianContainer = styled.div`
  .MuiPaper-root {
    margin-bottom: 30px;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(238, 238, 238, 1);
    -moz-box-shadow: 0px 0px 10px 5px rgba(238, 238, 238, 1);
    box-shadow: 0px 0px 10px 5px rgba(238, 238, 238, 1);
    .MuiExpansionPanelSummary-content.Mui-expanded {
      border-bottom: 1px solid ${props => props.theme.color.light};
      padding-bottom: 10px;
    }
    &.MuiExpansionPanel-root::before {
      background-color: transparent;
    }
    .MuiSvgIcon-root {
      color: ${props => props.theme.color.lightGrey};
    }
  }
  @media (min-width: 768px) {
  }
`

const Question = styled(ExpansionPanelSummary)`
  font-family: ${props => props.theme.font.galanoSemi};
  color: ${props => props.theme.color.dark};
  font-size: 1rem;
`

const Answer = styled(ExpansionPanelDetails)`
  font-size: 0.9rem;
  display: block;
  p {
    margin-bottom: 1rem;
    .strong {
      font-family: ${props => props.theme.font.galanoBold};
    }
  }
  a {
    display: inline-block;
    padding-bottom: 2px;
    border-bottom: 2px solid ${props => props.theme.color.orange};
  }
`

const Faq = ({ location }: Props) => {
  const [expanded, setExpanded] = useState(false)
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  useEffect(() => {
    if (location.state.panel) {
      let faqScroll = location.state.panel
      scroller.scrollTo(`${faqScroll}`, {
        duration: 750,
        delay: 100,
        smooth: true,
        offset: -150,
      })
      setExpanded(`${faqScroll}`)
    }
    /* eslint-disable */
  }, [])
  return (
    <PageWrapper>
      <Header>
        <h1>Frequently asked questions</h1>
        <p>
          Got questions? Find the answer here!
          <span />
        </p>
      </Header>
      <Section>
        <h2>Getting Started</h2>
        <AccordianContainer>
          <ExpansionPanel
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            name="panel1"
          >
            <Question
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <p className="question">Choosing photos - tips</p>
            </Question>
            <Answer>
              <p className="answer">
                You probably have hundres of photos of a special event and
                selecting which ones to print can be overwhelming. Try to limit
                your selection to include those that truly move you or bring you
                back to the day. Sound impossible? Not to worry. We recommend
                first going through and picking 20 absolute favorites: the
                photos you couldn&#39;t live without, key moments, and shots of
                the most important people in your life. Next, select another 40
                – 50 favorites that span the gamut of the day. Think:
                scene-setters, a few of your favorite details, that portrait you
                gush over. This approach to selecting images will help you to
                create a photo book that&#39;s thoughtfully curated without
                missing a favorite.
              </p>
            </Answer>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <Question
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2h-header"
            >
              <p>Choosing the book – a guide</p>
            </Question>
            <Answer>
              <p>
                Think about the purpose and usage of the book you&#39;re
                creating. Is this book going to be displayed on your coffee
                table? Or is it a gift for friends and family ? Here are some
                tips from us:
              </p>
              <h4>Flush Mounted Photo Book</h4>
              <p>
                <strong>Best for:</strong> panoramic wow factor <br />
                <strong>Why we love it:</strong> thick unbending pages
              </p>

              <h4>Layflat Photo Book</h4>
              <p>
                <strong>Best for:</strong> showing off that amazing photo <br />
                <strong>Why we love it:</strong> no fold when the book opens
              </p>

              <h4>Standard Photo Book</h4>
              <p>
                <strong>Best for:</strong> telling stories <br />
                <strong>Why we love it:</strong> really affordable
              </p>

              <h4>Hard Cover</h4>
              <p>
                <strong>Best for:</strong> building a collection <br />
                <strong>Why we love it:</strong> looks amazing on your bookshelf
              </p>
            </Answer>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            id="panel3"
          >
            <Question
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3h-header"
            >
              <p>Choosing the title – here’s how</p>
            </Question>
            <Answer>
              <p>
                Choose a cover title with timeless appeal. Simple titles can
                have great impact, but that doesn&#39;t mean you need to stick
                to the basics. Consider a title that speaks to your wedding for
                example in a unique yet timeless way. Some of our favorites?
                Est. 2015, MMXV, Volume I, 28 August 2015, or simply your last
                name.
              </p>
            </Answer>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <Question
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4h-header"
            >
              <p>What file format and size should my images be ?</p>
            </Question>
            <Answer>
              <p>
                {" "}
                JPG or PNG format. PNGs should be 8-bit and non-interlaced. SRGB
                color (no CMYK, Grayscale, and Adobe 1998 RGB). 150 to 300 DPI
                (dots per inch or pixels per inch). For instance, a 5x7 image
                frame should be filled with an image that is at least 750x1050
                pixels and no greater than 1500 to 2100 pixels.
              </p>
            </Answer>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <Question
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5bh-content"
              id="panel5h-header"
            >
              <p>How do I scan my images ?</p>
            </Question>
            <Answer>
              <p>
                When scanning your images, ensure they&#39;re free of dust or
                dirt otherwise these imperfections will turn up in the final
                product. You may also need to crop your photos after scanning to
                ensure there are no white edges.
              </p>
              <p>
                If you have the computer resources it would be beneficial to
                scan all your photos at a high dpi value (300 – 600 dpi). This
                will allow you greater freedom when deciding where and how to
                display your photos in your layout.
              </p>
              <p>
                We recommend that you do not scan your images higher than 600
                dpi as this won&#39;t introduce any greater quality but it will
                slow down your computer as the files will become very large.
                This may also introduce file processing issues if the files are
                too large, our software won’t be able to process images with a
                file size larger than 15 mb.
              </p>
            </Answer>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
          >
            <Question
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel6bh-content"
              id="panel6h-header"
            >
              <p>What resolution should my images be ?</p>
            </Question>
            <Answer>
              <p>
                Ideally pictures used in our software should be 300dpi. Most
                digital cameras won’t allow pictures to be taken at 300dpi,
                though some will offer a higher quality image through a setting
                on the camera such as &#34;High&#34; or &#34;Fine&#34;.
              </p>
              <p>
                The golden rule is that if you put quality pictures in, you will
                get quality pictures back. Neither our software nor print
                processes will add any more detail to your pictures that is
                already there, so you should note this when using pictures that
                are of inferior quality.
              </p>
              <p>We do not recommend using images from social media.</p>
            </Answer>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
          >
            <Question
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel7bh-content"
              id="panel7h-header"
            >
              <p>Can I improve the quality of my photos ?</p>
            </Question>
            <Answer>
              <p>
                It&#39;s not possible to increase the quality of your photos as
                the resolution of each photo is determined by the camera used to
                take the photo. As such, there&#39;s no process that you can
                apply to photos you&#39;ve already taken to improve their
                quality.
              </p>
              <p>
                If you received the photos in an email from another person,
                it&#39;s possible they may have sent you a lower resolution
                version. In this case it might be possible to obtain the
                original photos (which are greater in size and quality).
                Similarly, if you&#39;ve pre-processed your photos to a smaller
                size, it would be worth sourcing the original photos directly
                from the camera.
              </p>
            </Answer>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === "panel8"}
            onChange={handleChange("panel8")}
          >
            <Question
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel8bh-content"
              id="panel8h-header"
            >
              <p>What is DPI resolution?</p>
            </Question>
            <Answer>
              <p>
                For more information on resolution and printing results read
                this article:{" "}
                <a
                  href="https://www.photoshopessentials.com/essentials/image-quality/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.photoshopessentials.com/essentials/image-quality/
                </a>
              </p>
            </Answer>
          </ExpansionPanel>
        </AccordianContainer>
      </Section>
      <Section>
        <h2>Creating Your Photo Book</h2>
        <AccordianContainer>
          <ExpansionPanel
            expanded={expanded === "panel10"}
            onChange={handleChange("panel10")}
          >
            <Question
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel10bh-content"
              id="panel10bh-header"
            >
              <p className="question">How long does it take ?</p>
            </Question>
            <Answer>
              <p>
                Once we have received your images it takes 15 working days for
                us to design and print your photo book. Allow an additional 2 to
                3 days for courier delivery to your door.
              </p>
            </Answer>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === "panel11"}
            onChange={handleChange("panel11")}
          >
            <Question
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel11bh-content"
              id="panel11bh-header"
            >
              <p className="question">Can I cancel my order ?</p>
            </Question>
            <Answer>
              <p>
                The moment we receive your order we start working on it in in
                order to deliver it to you in the fastest time possible.
              </p>
              <p>
                Our team starts very early in the morning and we have highly
                automated printing presses and workflows in our 1 600 sqm
                factory that do not allow for interruptions.
              </p>
              <p>
                So unfortunately as a rule we do not accept cancellations and
                changes to orders.
              </p>
            </Answer>
          </ExpansionPanel>
        </AccordianContainer>
      </Section>
      <Section>
        <h2>Receiving Your Photo Book</h2>
        <AccordianContainer>
          <ExpansionPanel
            expanded={expanded === "panel12"}
            onChange={handleChange("panel12")}
          >
            <Question
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel12bh-content"
              id="panel12bh-header"
            >
              <p>How do I make changes to my shipping instructions?</p>
            </Question>
            <Answer>
              <p>
                The moment we receive your order we start working on it in in
                order to deliver it to you in the fastest time possible. We
                understand that mistakes happen, so we will accept changes to
                your shipping address within 24 hours of placing you order.
              </p>
              <p>
                You will need to phone us immediately and confirm your shipping
                address changes with a follow-up email. If you placed your order
                when our Client Care was closed, please phone us as soon as we
                are open and we will try our best to assist you.
              </p>
            </Answer>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === "panel13"}
            onChange={handleChange("panel13")}
          >
            <Question
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel13bh-content"
              id="panel13bh-header"
            >
              <p>
                What does it cost to make changes to my shipping instructions?
              </p>
            </Question>
            <Answer>
              <p>
                Changes to shipping instructions will be charged an
                administration fee of R100 over and above the courier costs if
                any.
              </p>
            </Answer>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === "panel14"}
            onChange={handleChange("panel14")}
          >
            <Question
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel14bh-content"
              id="panel14bh-header"
            >
              <p>How long does it take for an order to be delivered to me?</p>
            </Question>
            <Answer>
              <p>
                After production, delivery to your doorstep can take anywhere
                from 2 to 3 working days. However, during peak seasons please
                allow for up to 5 working days.
              </p>
            </Answer>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === "panel15"}
            onChange={handleChange("panel15")}
          >
            <Question
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel15bh-content"
              id="panel15bh-header"
            >
              <p>What happens if I am not at home to receive the order?</p>
            </Question>
            <Answer>
              <p>
                The courier will contact you up to 3 times, if you are
                unavailable and they cannot make delivery, the order will be
                returned to Q-Photo. We will contact you for delivery
                arrangements which will be for your cost.
              </p>
            </Answer>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === "panel16"}
            onChange={handleChange("panel16")}
          >
            <Question
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel16bh-content"
              id="panel16bh-header"
            >
              <p>Can I track my order delivery progress with the courier?</p>
            </Question>
            <Answer>
              <p>
                Yes, your waybill number has been sent to you by Q-Photo via SMS
                and email. Please use this number to track your order with the
                following links below;
              </p>
              <p>Door-to door, click here.</p>
            </Answer>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === "panel17"}
            onChange={handleChange("panel17")}
          >
            <Question
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel17bh-content"
              id="panel17bh-header"
            >
              <p>
                If I am from a country outside of South Africa do I have to pay
                import duty?
              </p>
            </Question>
            <Answer>
              <p>
                Import Duties apply to customers who reside in another country
                receiving orders from Q-Photo. Depending on your country and the
                products ordered import duties range from 10 % or more than 40
                %. Please check with the relevant authorities or any
                international shipping company.
              </p>
            </Answer>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === "panel18"}
            onChange={handleChange("panel18")}
          >
            <Question
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel18bh-content"
              id="panel18bh-header"
            >
              <p>Do you allow third party courier services</p>
            </Question>
            <Answer>
              <p>
                Unfortunately, not, Q-Photo does not allow any other courier
                companies for shipping and work exclusively through our
                preferred provider(s) to bring you the best service.
              </p>
            </Answer>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === "panel19"}
            onChange={handleChange("panel19")}
          >
            <Question
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel19bh-content"
              id="panel19bh-header"
            >
              <p>What do I do if I am not happy with my order ?</p>
            </Question>
            <Answer>
              <p>
                We aim to delight you, so please let us know if we fall short of
                our ambitions in any way. If you are unhappy with your order for
                any reason, please contact us within 14 days and we will do our
                best to make it right.
              </p>
              <p>
                You will be required to return the defective product with the
                original tax invoice for our inspection. Our management team
                will review the situation and respond to you with an outcome
                within 24 hours.
              </p>
              <p>
                Build My Book will only provide a refund or a replacement of a
                product if the defect is due to a manufacturing error that was
                caused by Q-Photo. We will also correct any spelling or grammar
                errors free of charge.
              </p>
            </Answer>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === "panel20"}
            onChange={handleChange("panel20")}
          >
            <Question
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel20bh-content"
              id="panel20bh-header"
            >
              <p>Client initiated product damage:</p>
            </Question>
            <Answer>
              <p>
                Build My Book does not provide refunds or replacement for damage
                to products caused by normal wear-and-tear, unusual handling,
                usage or treatment.
              </p>
              <p>
                Failure to adhere to Care Guidelines as published on this
                website will also void the quality guarantee.
              </p>
            </Answer>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === "panel21"}
            onChange={handleChange("panel21")}
          >
            <Question
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel21bh-content"
              id="panel21bh-header"
            >
              <p>Care Guidelines:</p>
            </Question>
            <Answer>
              <p>
                Keep your photo book out of direct sunlight. Clean it with a
                slightly damp cloth. High rainfall at coastal areas may cause
                the pages to warp however they will return to the original shape
                over time. Guaranteed. Page carefully through the book and if
                you have standard photo book to not push down on the open pages
                to flatten the binding, rather order a layflat photo book.
              </p>
            </Answer>
          </ExpansionPanel>
        </AccordianContainer>
      </Section>
    </PageWrapper>
  )
}

export default Faq
